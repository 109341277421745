import { ADDONS_IDS } from '@constants/addonsIds'
import {
  UPGRADE_MODALS,
  UPGRADE_MODALS_NAMES_FOR_MIXPANEL
} from '@constants/modals'
import { useFeatureFlags } from '@contexts/featureFlags'
import agencyHubUpgrade from '@images/modals/agency-hub-upgrade.png'
import agencyUpgrade from '@images/modals/agency-upgrade.jpg'
import domainsUpgrade from '@images/modals/domains-upgrade.png'
import eCommerceUpgrade from '@images/modals/ecommerce-upgrade.png'
import fontsUpgrade from '@images/modals/fonts-upgrade.png'
import lightboxesUpgrade from '@images/modals/lightboxes-upgrade.jpg'
import optimizationUpgrade from '@images/modals/optimization-upgrade.jpg'
import popupsUpgrade from '@images/modals/popups-upgrade.jpg'
import programmaticUpgradeEn from '@images/modals/programmatic-upgrade-en.png'
import programmaticUpgradePl from '@images/modals/programmatic-upgrade-pl.png'
import publishUpgrade from '@images/modals/publish-upgrade.png'
import smartSectionsUpgrade from '@images/modals/smart-sections-upgrade.png'
import translateUpgradeEN from '@images/modals/translate-upgrade-en.png'
import translateUpgradePL from '@images/modals/translate-upgrade-pl.png'
import { Trans, useTranslation } from 'react-i18next'

import { PrimaryAddonButton } from '../components/PrimaryAddonButton'
import { PrimaryButton } from '../components/PrimaryButton'
import { SecondaryButton } from '../components/SecondaryButton'

export const useUpgradeModalContent = () => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()

  const hasAccessToAddonsStore = useFeatureFlags('ADDONS_STORE')
  const hasSemrushIntegrationFF = useFeatureFlags('SEMRUSH_INTEGRATION')

  return {
    [UPGRADE_MODALS.DOMAIN]: {
      modalTitle: t('domain.limit.upgrade.title'),
      featureHeading: t('domain.limit.upgrade.feature.heading'),
      featureDescription: t('domain.limit.upgrade.feature.description'),
      imageSrc: domainsUpgrade,
      primaryButton: (
        <PrimaryButton
          featureNameForMixpanel={UPGRADE_MODALS_NAMES_FOR_MIXPANEL.DOMAINS}
        />
      ),
      hideFooter: false,
      featureNameForMixpanel: UPGRADE_MODALS_NAMES_FOR_MIXPANEL.DOMAINS
    },
    [UPGRADE_MODALS.PUBLISH_LIMIT]: {
      modalTitle: t('publish.limit.upgrade.modal.title'),
      featureHeading: t('publish.limit.upgrade.feature.heading'),
      featureDescription: t('publish.limit.upgrade.feature.description'),
      imageSrc: publishUpgrade,
      primaryButton: <PrimaryButton />,
      hideFooter: false
    },
    [UPGRADE_MODALS.SUBACCOUNT_PUBLISH_LIMIT]: {
      modalTitle: t('publish.limit.subaccount.upgrade.feature.title'),
      featureHeading: hasSemrushIntegrationFF
        ? t('publish.limit.subaccount.upgrade.feature.heading.semrush')
        : t('publish.limit.subaccount.upgrade.feature.heading'),
      hideFooter: true,
      imageSrc: publishUpgrade,
      featureDescription: null,
      secondaryButton: null,
      primaryButton: <PrimaryButton />
    },
    [UPGRADE_MODALS.AGENCY]: {
      modalTitle: t('agency.upgrade.modal.title'),
      featureHeading: t('agency.upgrade.modal.feature.heading'),
      featureDescription: t('agency.upgrade.modal.feature.description'),
      imageSrc: agencyUpgrade,
      secondaryButton: <SecondaryButton />,
      primaryButton: <PrimaryButton />,
      hideFooter: false
    },
    [UPGRADE_MODALS.SMART_SECTIONS]: {
      modalTitle: hasAccessToAddonsStore
        ? t('smart-section.upgrade.modal.title.addon')
        : t('smart-section.upgrade.modal.title'),
      featureHeading: hasAccessToAddonsStore ? (
        t('upgrade.modal.description.addon')
      ) : (
        <Trans
          i18nKey='smart-section.upgrade.modal.heading'
          components={{
            b: <strong />
          }}
        />
      ),
      featureDescription: hasAccessToAddonsStore
        ? null
        : t('upgrade.modal.description'),
      imageSrc: smartSectionsUpgrade,
      secondaryButton: hasAccessToAddonsStore ? null : (
        <SecondaryButton
          featureNameForMixpanel={
            UPGRADE_MODALS_NAMES_FOR_MIXPANEL.SMART_SECTIONS
          }
        />
      ),
      primaryButton: hasAccessToAddonsStore ? (
        <PrimaryAddonButton
          addonId={ADDONS_IDS.SMART_SECTIONS}
          featureNameForMixpanel={
            UPGRADE_MODALS_NAMES_FOR_MIXPANEL.SMART_SECTIONS
          }
        >
          {t('smart-sections.upgrade.modal.primary.button')}
        </PrimaryAddonButton>
      ) : (
        <PrimaryButton
          featureNameForMixpanel={
            UPGRADE_MODALS_NAMES_FOR_MIXPANEL.SMART_SECTIONS
          }
        />
      ),
      hideFooter: false,
      featureNameForMixpanel: UPGRADE_MODALS_NAMES_FOR_MIXPANEL.SMART_SECTIONS
    },
    [UPGRADE_MODALS.POPUPS]: {
      modalTitle: hasAccessToAddonsStore
        ? t('popups.upgrade.modal.title.grab.attention')
        : t('popups.upgrade.modal.title'),
      featureHeading: hasAccessToAddonsStore ? (
        <Trans i18nKey='popups.upgrade.modal.heading.dont.let.the.visitors' />
      ) : (
        t('popups.upgrade.modal.heading')
      ),
      featureDescription: hasAccessToAddonsStore
        ? t('popups.upgrade.modal.description.set.triggers')
        : t('upgrade.modal.description'),
      imageSrc: popupsUpgrade,
      secondaryButton: hasAccessToAddonsStore ? null : (
        <SecondaryButton
          featureNameForMixpanel={UPGRADE_MODALS_NAMES_FOR_MIXPANEL.POPUPS}
        />
      ),
      primaryButton: hasAccessToAddonsStore ? (
        <PrimaryAddonButton
          addonId={ADDONS_IDS.POPUPS_ADDON}
          featureNameForMixpanel={UPGRADE_MODALS_NAMES_FOR_MIXPANEL.POPUPS}
        >
          {t('popups.upgrade.modal.primary.button')}
        </PrimaryAddonButton>
      ) : (
        <PrimaryButton
          featureNameForMixpanel={UPGRADE_MODALS_NAMES_FOR_MIXPANEL.POPUPS}
        />
      ),
      hideFooter: false,
      featureNameForMixpanel: UPGRADE_MODALS_NAMES_FOR_MIXPANEL.POPUPS
    },
    [UPGRADE_MODALS.LIGHTBOXES]: {
      modalTitle: t('lightboxes.upgrade.modal.title'),
      featureHeading: t('lightboxes.upgrade.modal.heading'),
      featureDescription: t('upgrade.modal.description'),
      imageSrc: lightboxesUpgrade,
      secondaryButton: <SecondaryButton />,
      primaryButton: <PrimaryButton />,
      hideFooter: false
    },
    [UPGRADE_MODALS.OPTIMIZATION]: {
      modalTitle: hasAccessToAddonsStore
        ? t('optimization.upgrade.modal.title.deliver.whats.best')
        : t('optimization.upgrade.modal.title'),
      featureHeading: hasAccessToAddonsStore ? (
        <Trans
          i18nKey='optimization.upgrade.modal.heading.increase.conversion.using.ab.tests'
          components={{
            b: <strong />
          }}
        />
      ) : (
        t('optimization.upgrade.modal.heading')
      ),
      featureDescription: hasAccessToAddonsStore
        ? null
        : t('upgrade.modal.description'),
      imageSrc: optimizationUpgrade,
      secondaryButton: hasAccessToAddonsStore ? null : (
        <SecondaryButton
          featureNameForMixpanel={
            UPGRADE_MODALS_NAMES_FOR_MIXPANEL.OPTIMIZATION
          }
        />
      ),
      primaryButton: hasAccessToAddonsStore ? (
        <PrimaryAddonButton
          addonId={ADDONS_IDS.OPTIMIZATION_ADDON}
          featureNameForMixpanel={
            UPGRADE_MODALS_NAMES_FOR_MIXPANEL.OPTIMIZATION
          }
        >
          {t('optimization.upgrade.modal.primary.button')}
        </PrimaryAddonButton>
      ) : (
        <PrimaryButton
          featureNameForMixpanel={
            UPGRADE_MODALS_NAMES_FOR_MIXPANEL.OPTIMIZATION
          }
        />
      ),
      hideFooter: false,
      featureNameForMixpanel: UPGRADE_MODALS_NAMES_FOR_MIXPANEL.OPTIMIZATION
    },
    [UPGRADE_MODALS.ECOMMERCE]: {
      modalTitle: t('ecommerce.upgrade.modal.title'),
      featureHeading: t('ecommerce.upgrade.modal.heading'),
      imageSrc: eCommerceUpgrade,
      primaryButton: (
        <PrimaryAddonButton
          addonId={ADDONS_IDS.ECOMMERCE_ADDON}
          featureNameForMixpanel={UPGRADE_MODALS_NAMES_FOR_MIXPANEL.ECOMMERCE}
        >
          {t('ecommerce.upgrade.modal.button')}
        </PrimaryAddonButton>
      ),
      featureNameForMixpanel: UPGRADE_MODALS_NAMES_FOR_MIXPANEL.ECOMMERCE
    },
    [UPGRADE_MODALS.FONTS]: {
      modalTitle: t('fonts.upgrade.modal.title'),
      featureHeading: t('fonts.upgrade.modal.heading'),
      featureDescription: t('upgrade.modal.description'),
      imageSrc: fontsUpgrade,
      secondaryButton: (
        <SecondaryButton
          featureNameForMixpanel={UPGRADE_MODALS_NAMES_FOR_MIXPANEL.FONTS}
        />
      ),
      primaryButton: (
        <PrimaryButton
          featureNameForMixpanel={UPGRADE_MODALS_NAMES_FOR_MIXPANEL.FONTS}
        />
      ),
      hideFooter: false,
      featureNameForMixpanel: UPGRADE_MODALS_NAMES_FOR_MIXPANEL.FONTS
    },
    [UPGRADE_MODALS.TRANSLATE]: {
      modalTitle: t('lp.translate.upgrade.modal.title'),
      featureHeading: t('lp.translate.upgrade.modal.description'),
      imageSrc:
        i18n.language === 'pl' ? translateUpgradePL : translateUpgradeEN,
      secondaryButton: (
        <SecondaryButton
          featureNameForMixpanel={UPGRADE_MODALS_NAMES_FOR_MIXPANEL.TRANSLATE}
        />
      ),
      primaryButton: (
        <PrimaryButton
          featureNameForMixpanel={UPGRADE_MODALS_NAMES_FOR_MIXPANEL.TRANSLATE}
        />
      ),
      hideFooter: false,
      featureNameForMixpanel: UPGRADE_MODALS_NAMES_FOR_MIXPANEL.TRANSLATE
    },
    [UPGRADE_MODALS.AGENCY_HUB]: {
      modalTitle: t('agency.hub.upgrade.modal.title'),
      featureHeading: t('agency.hub.upgrade.modal.feature.heading'),
      featureDescription: t('agency.hub.upgrade.modal.feature.description'),
      imageSrc: agencyHubUpgrade,
      secondaryButton: (
        <SecondaryButton
          customContent={t('modal.upgrade.secondary.button.schedule.demo')}
          customUrl={t('modal.upgrade.secondary.button.schedule.demo.url')}
          featureNameForMixpanel={UPGRADE_MODALS_NAMES_FOR_MIXPANEL.AGENCY_HUB}
        />
      ),
      primaryButton: (
        <PrimaryAddonButton
          addonId={ADDONS_IDS.AGENCY}
          featureNameForMixpanel={UPGRADE_MODALS_NAMES_FOR_MIXPANEL.AGENCY_HUB}
        >
          {t('modal.upgrade.primary.button.learn.more')}
        </PrimaryAddonButton>
      ),
      hideFooter: false,
      featureNameForMixpanel: UPGRADE_MODALS_NAMES_FOR_MIXPANEL.AGENCY_HUB
    },
    [UPGRADE_MODALS.PROGRAMMATIC]: {
      modalTitle: t('programmatic.upgrade.modal.title'),
      featureHeading: t('programmatic.upgrade.modal.feature.heading'),
      imageSrc:
        i18n.language === 'pl' ? programmaticUpgradePl : programmaticUpgradeEn,
      secondaryButton: false,
      primaryButton: (
        <PrimaryAddonButton
          addonId={ADDONS_IDS.PROGRAMMATIC_LP}
          featureNameForMixpanel={
            UPGRADE_MODALS_NAMES_FOR_MIXPANEL.PROGRAMMATIC
          }
        >
          {t('programmatic.upgrade.modal.button')}
        </PrimaryAddonButton>
      ),
      hideFooter: false,
      featureNameForMixpanel: UPGRADE_MODALS_NAMES_FOR_MIXPANEL.PROGRAMMATIC
    }
  }
}
