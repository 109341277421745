import { MODAL_UPGRADE_PLAN } from '@components/Modals/ModalUpgradePlan'
import { EDITOR_DOMAIN } from '@config/env'
import {
  COPY_AND_CLOSE_PROPERTY_VALUE,
  COPY_AND_EDIT_PROPERTY_VALUE,
  DUPLICATE_LP_EVENT_NAME
} from '@constants/mixpanel'
import { UPGRADE_MODALS } from '@constants/modals'
import { useAcl } from '@contexts/acl'
import { useFeatureFlags } from '@contexts/featureFlags'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { wait } from '@helpers/promise'
import { useLazyService } from '@hooks/useLazyService'
import { Input } from '@landingi/landingi-ui-kit'
import { MODAL_LANDING_TRANSLATE } from '@pages/Landings/components/Modals/ModalLandingTranslate'
import { LANDINGS } from '@routes/path'
import { duplicateLanding } from '@services/landings/duplicate'
import { mixpanelEvent } from '@services/mixpanel'
import { Alert, Button, Spacer, Spreader } from '@ui-kit'
import { useFormik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

interface ModalLandingDuplicateFormProps {
  name: string
  uuid: string
  resetFilterAndSorter?: () => void
}

export const ModalLandingDuplicateForm: FC<ModalLandingDuplicateFormProps> = ({
  name,
  uuid,
  resetFilterAndSorter
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { remove } = useModal()

  const FREE_AI_GENERATION_FF = useFeatureFlags('FREE_AI_GENERATION')
  const hasAccessToTranslateAction = useAcl('landing_pages.landing.translate')
  const hasAccessToTranslateButton = useAcl(
    'landing_pages.landing.translate_show_button'
  )
  const hasAccessToTranslate = FREE_AI_GENERATION_FF
    ? hasAccessToTranslateButton
    : hasAccessToTranslateAction

  const [handleDuplicateLanding, { isLoading }] = useLazyService(
    duplicateLanding,
    {
      onSuccess: async () => {
        // added delay to allow the read model to be updated
        await wait(1000)

        if (resetFilterAndSorter) {
          resetFilterAndSorter()
        }
      },
      successToastText: t('modal.landings.duplicate.toast'),
      t,
      handleErrorMessages: true
    }
  )

  const initialValues = {
    name: `${name} - ${t('word.copy')}`
  }

  const validationSchema = toFormikValidationSchema(
    z.object({
      name: z
        .string({
          required_error: t('form.validation.isRequired')
        })
        .refine(value => value !== name)
    })
  )

  const {
    isSubmitting,
    handleSubmit,
    isValid,
    handleChange,
    values,
    submitForm
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ name }) => {
      const data = await handleDuplicateLanding({ uuid, name })

      return data
    }
  })

  const [sendMixpanelEvent] = useLazyService(mixpanelEvent)

  const handleSaveAndClose = async () => {
    const data = await submitForm()

    if (data?.newMasterVariantHash) {
      remove()

      // added delay to allow the read model to be updated
      await wait(1000)

      await sendMixpanelEvent({
        name: DUPLICATE_LP_EVENT_NAME,
        properties: {
          value: COPY_AND_CLOSE_PROPERTY_VALUE
        }
      })

      navigate(LANDINGS.LANDINGS)
    }
  }

  const handleSaveAndStartEditing = async () => {
    const data = await submitForm()

    await sendMixpanelEvent({
      name: DUPLICATE_LP_EVENT_NAME,
      properties: {
        value: COPY_AND_EDIT_PROPERTY_VALUE
      }
    })

    if (data?.newMasterVariantHash) {
      window.open(`${EDITOR_DOMAIN}/${data.newMasterVariantHash}`, '_self')
    }
  }

  const handleTranslate = () => {
    if (!hasAccessToTranslateAction) {
      NiceModal.show(MODAL_UPGRADE_PLAN, {
        upgradeType: UPGRADE_MODALS.TRANSLATE
      })
    } else {
      NiceModal.show(MODAL_LANDING_TRANSLATE, {
        uuid,
        name
      })
    }

    remove()
  }

  const translateModalOpener = (
    <Button variant='text-primary' size={12} onClick={handleTranslate}>
      {t('duplicate.modal.try.translate')}
    </Button>
  )

  return (
    <form onSubmit={handleSubmit}>
      <Row wrap>
        <Column flex='1'>
          <Input
            name='name'
            defaultValue={name}
            i18n={{
              placeholder: t('modal.landing.duplicate.placeholder'),
              label: t('modal.landing.duplicate.label')
            }}
            onChange={handleChange}
            value={values.name}
          />
        </Column>
      </Row>

      <Spacer space={10} />

      {hasAccessToTranslate && (
        <Alert variant='info' additionalContent={translateModalOpener}>
          {t('duplicate.modal.propose.translate')}
        </Alert>
      )}

      <Spacer space={30} />

      <Row flex='1' justifyContent='end'>
        <Row>
          <Button
            variant='text-primary'
            disabled={!isValid || isSubmitting}
            onClick={handleSaveAndClose}
          >
            {t('label.copy-and-close')}
          </Button>

          <Spreader spread={10} />

          <Button
            isLoading={isSubmitting || isLoading}
            disabled={!isValid || isSubmitting}
            onClick={handleSaveAndStartEditing}
          >
            {t('label.edit-start')}
          </Button>
        </Row>
      </Row>
    </form>
  )
}

ModalLandingDuplicateForm.displayName = 'ModalLandingDuplicateForm'
