import { MODAL_UPGRADE_PLAN } from '@components/Modals/ModalUpgradePlan'
import { EDITOR_DOMAIN } from '@config/env'
import { UPGRADE_MODALS } from '@constants/modals'
import { useAcl } from '@contexts/acl'
import { useFeatureFlags } from '@contexts/featureFlags'
import NiceModal from '@ebay/nice-modal-react'
import { MODAL_LANDING_TRANSLATE } from '@pages/Landings/components/Modals/ModalLandingTranslate'
import { useDashboardContext } from '@pages/Landings/routes/Dashboard/context'
import { Button, ButtonLink, Spreader } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import { DropdownListItems } from './DropdownListItems'

export const Actions = () => {
  const { t } = useTranslation()

  const { landing } = useDashboardContext()

  const hasLandingAccessEdit = useAcl('landing_pages.landing.edit')

  const FREE_AI_GENERATION_FF = useFeatureFlags('FREE_AI_GENERATION')
  const hasAccessToTranslateAction = useAcl('landing_pages.landing.translate')
  const hasAccessToTranslateButton = useAcl(
    'landing_pages.landing.translate_show_button'
  )
  const hasAccessToTranslate = FREE_AI_GENERATION_FF
    ? hasAccessToTranslateButton
    : hasAccessToTranslateAction

  const { master_hash, identifier, name } = landing

  const handleTranslate = () => {
    if (hasAccessToTranslateAction) {
      NiceModal.show(MODAL_LANDING_TRANSLATE, {
        uuid: identifier,
        name
      })
    } else {
      NiceModal.show(MODAL_UPGRADE_PLAN, {
        upgradeType: UPGRADE_MODALS.TRANSLATE
      })
    }
  }

  return (
    <Row justifyContent='flex-end' alignContent='flex-start'>
      {hasLandingAccessEdit ? (
        <ButtonLink
          target='_blank'
          href={`${EDITOR_DOMAIN}/${master_hash}`}
          icon='icon-create'
          variant='primary'
        >
          {t('word.edit')}
        </ButtonLink>
      ) : null}

      <Spreader spread={10} />

      {hasAccessToTranslate ? (
        <Fragment>
          <Button
            id='hj-translate-button-primary'
            variant='text-primary'
            icon='icon-ai-option'
            onClick={handleTranslate}
          >
            {t('word.lp.translate')}
          </Button>

          <Spreader spread={10} />
        </Fragment>
      ) : null}

      <DropdownListItems />
    </Row>
  )
}

Actions.displayName = 'LandingsDashboardHeaderRowActions'
